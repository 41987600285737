main {
	--link-color: #049ccf;
	--link-decoration: underline;
}

article p:first-letter {
	margin-left: 2em;
}

.meta-author-links.flex {
	gap: 12px;
}
